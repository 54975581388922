export const sidebarButtonText = [
  'Create Tasks',
  'Marking Templates',
  'Comment banks',
  'View Class Insights',
  'AI-Assisted Marking',
  'Sharing Exemplars',
];

export const mainSectionContent = [
  {
    title: 'Create Tasks',
    subTitle:
      "Click the 'New Task' button in the top right corner to create, customise and assign a writing task for your classes.",
    image: '/img/createTaskOnboarding.svg',
    buttonText: 'Create New Task',
    link: '#/tasks/new',
  },
  {
    title: 'Add Marking Templates',
    subTitle:
      "Use a preloaded marking template or create your own to provide overall feedback for a task in a few clicks. Use either a performance-based rubric OR a specific list of strengths and target areas.",
    image: '/img/markingTemplateOnboarding.svg',
    buttonText: 'Add Marking Template',
    link: '#/settings',
  },
  {
    title: 'Comment Banks',
    subTitle:
      "Create your own comment banks to speed up the marking process, or add/customise a preloaded comment bank, which can be found in your Marking Tools.",
    image: '/img/commentBankOnboarding.svg',
    buttonText: 'Add Comment Banks',
    link: '#/commentbanks',
  },
  {
    title: 'View Class Insights',
    subTitle:
      "Identify skill gaps with real-time insights based on the most common areas of feedback.",
    image: '/img/viewClassInsightsOnboarding.svg',
    buttonText: 'View Insights',
    link: '#/classes',
  },
  {
    title: 'AI-Assisted marking',
    subTitle:
      "Use our teacher-trained AI model to produce instant annotations and feedback on a student submission. You can edit and supplement JeddAI's suggestions before submitting feedback, ensuring that you always have the final say.",
    image: '/img/aiMarkingTutorialImage.svg',
    buttonText: 'Try JeddAI',
    link: '#/getFeedback',
  },
  {
    title: 'Exemplary Responses',
    subTitle:
      'Help students learn from each other by sharing work in your class library',
    image: '/img/exemplarsOnboarding.svg',
    buttonText: 'View Exemplars',
    link: '#/sharedresponses',
  },
];
